import React, { useState } from 'react';
import './ReservationsPage.css';
import { firestore, setDoc, doc, Timestamp } from './firebase-config';

function ReservationsPage() {
  const [eventName, setEventName] = useState('');
  const [hostName, sethostName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const formattedEventName = eventName.trim().replace(/\s+/g, '-').toLowerCase();
    const startTimestamp = Timestamp.fromDate(new Date(startDate));
    const endTimestamp = Timestamp.fromDate(new Date(endDate));
    const eventUrl = `https://app.snappo.io/${formattedEventName}`;

    try {
      const eventDocRef = doc(firestore, 'events', formattedEventName);
      await setDoc(eventDocRef, {
        eventName: formattedEventName,
        eventUrl,
        hostName,
        startDate: startTimestamp,
        endDate: endTimestamp,
        createdAt: new Date(),
      });
      setMessage('Reservation submitted successfully!');
      // Clear the form
      setEventName('');
      sethostName('');
      setStartDate('');
      setEndDate('');
    } catch (error) {
      setMessage('Error submitting reservation. Please try again.');
      console.error('Error adding document: ', error);
    }

    setLoading(false);
  };


  return (
    <section id="features">
      <h2>Reservations</h2>
      <div className="reservation-form">
        <h3>Book Your Event</h3>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="event-name">Event Name:</label>
            <input
              type="text"
              id="event-name"
              value={eventName}
              onChange={(e) => setEventName(e.target.value)}
              placeholder="Enter your event name"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="event-name">Host Name:</label>
            <input
              type="text"
              id="host-name"
              value={hostName}
              onChange={(e) => sethostName(e.target.value)}
              placeholder="Enter your host name"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="start-date">Start Date:</label>
            <input
              type="date"
              id="start-date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="end-date">End Date:</label>
            <input
              type="date"
              id="end-date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              required
            />
          </div>
          <button type="submit" disabled={loading}>
            {loading ? 'Submitting...' : 'Submit Reservation'}
          </button>
        </form>
        {message && <p className="message">{message}</p>}
      </div>
    </section>
  );
}

export default ReservationsPage;