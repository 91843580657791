import React from 'react';

function TestimonialsPage() {
  return (
    <section id="about">
      <h2>Testimonials</h2>
      <p>Testimonials will go here...</p>
    </section>
  );
}

export default TestimonialsPage;
