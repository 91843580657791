import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import ReservationsPage from './ReservationsPage';
import TestimonialsPage from './TestimonialsPage';
import FAQPage from './FAQPage';
import Container from './Container';
import Section from './Section';
import Card from './Card';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <nav>
          <h1><Link to="/">snappo</Link></h1>
          <ul>
              <li><Link to="/reservations">Reservations</Link></li>
              <li><Link to="/testimonials">Testimonials</Link></li>
              <li><Link to="/faq">FAQ</Link></li>
            </ul>
          </nav>
        </header>

        <Routes>
          <Route path="/" element={<Container><HomePage /></Container>} />
          <Route path="/reservations" element={<Container><ReservationsPage /></Container>} />
          <Route path="/testimonials" element={<Container><TestimonialsPage /></Container>} />
          <Route path="/faq" element={<Container><FAQPage /></Container>} />
        </Routes>

        <footer>
          <p>&copy; 2024 Snappo. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
}

function HomePage() {
  return (
    <div className="homepage">
      <Section title="Welcome to Our Product">
        <p className="hero-description">
          Discover the best solution for your business needs. Experience seamless integration and maximum productivity.
        </p>
        <button className="primary-button">Get Started</button>
      </Section>

      <div className="features">
        <Card title="Feature 1">
          <p>Discover the best solution for your business needs with our first feature.</p>
        </Card>

        <Section title="Feature 2">
          <p>Explore more functionality and experience seamless integration with our second feature.</p>
        </Section>

        <Section title="Feature 3">
          <p>Maximize productivity and efficiency with our third feature.</p>
        </Section>
      </div>
    </div>
  );
}

export default App;
