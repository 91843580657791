import React from 'react';

function FAQPage() {
  return (
    <section id="contact">
      <h2>FAQ</h2>
      <p>info@snappo.io</p>
    </section>
  );
}

export default FAQPage;
